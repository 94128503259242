.password-reset {
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
  h3 {
    margin: 0;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .left-box {
    border: 2px solid $brand-red;
    padding: 24px;
  }
  label {
    // height: 42px;
    // border-right:0;
    display: flex;
    flex-direction: column;
    // padding: 16px;
    &#conf-pw-label {
      // border-top: 1px dotted $grey;
      padding-top: 24px;
    }
  }

  input {
    // margin-top: 20px;
    color: #bebebe;
    border: 0;
    font-size: 24px;
    padding-left: 16px;
    border-bottom: 1px solid grey;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 32px;
    &::-webkit-input-placeholder {
      /* Edge */
      color: $grey-light;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey-light;
    }

    &::placeholder {
      color: $grey-light;
    }
  }
  button.button {
    width: 100%;
    margin: 20px 0 0 0;
  }
  form {
    border: 2px solid $brand-red;
    padding: 24px;
    margin-top: 24px;
  }
}

.signup-activation-page {
  display: flex;
  flex-direction: column;
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;

    form {
      border: 2px solid $brand-red;
      padding: 20px;
    }
    label {
      // height:42px;
      // border-right:0;
      display: flex;
      flex-direction: column;
      padding: 0 16px 16px 0;
    }

    input {
      //   margin-top:20px;
      color: #bebebe;
      border: 0;
      font-size: 24px;
      padding-left: 16px;
      border-bottom: 1px solid grey;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      &::placeholder {
        color: #dcdcdc;
      }
    }
    button.button {
      width: 100%;
      margin: 20px 0 0 0;
    }
    #conf-pw-label {
      padding-top: 20px;
      margin-top: 20px;
      // border-top: 2px solid $brand-red;
    }
  }
  p {
    margin: 0;
    font-size: 12px;
  }
  .loadcontainer {
    margin-top: 40px;
    .bar {
      width: 100%;
    }
  }
}
    .hidden {
      display: none;
    }

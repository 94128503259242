.nav-wrapper{
  background-color: $primary-dark;
  // height: 300px;
}
nav {
  // width: 100%;
  height: 40px;
  background-color: $primary-dark;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 20px;
  color: $grey-light;
  font-family: $font-regular;
  position: relative;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
.nav-icon{
  font-size:18px;
  margin-right:4px
}

  #menuToggle {
    display: block;
    position: relative;
    top: 0px;
    left: 10px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
    @media (min-width: $tablet) {
      width: 100%;
      left: 0;
      position: static;
    }
  }

  #menuToggle>input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
    @media (min-width: $tablet) {
      display: none;
    }
  }

  /*
  * Just a quick hamburger
  */
  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;
    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    @media (min-width: $tablet) {
      display: none;
    }
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }


  #menu {
    position: absolute;
    width: 100vw;
    margin: -35px 0 0 -50px;
    padding: 50px;
    padding-top: 40px;

    background: $primary-dark;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;


    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    @media (min-width: $tablet) {
      margin: 0;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // position: relative;
      width: 100%;
      position: static;
      transform: unset;
      transition: unset;
      margin: 0;
      padding: 0;
    }
  }

  #menu li {
    padding: 10px 0;
    // font-size: 22px;
  }

  /*
  * And let's slide it in from the left
  */
  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .social-bar{
    display: flex;
    flex-wrap: nowrap;
    margin-right:20px;
    align-items: center;
    a{
      display: flex;
    }
  }

}


.navmenuitem a {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: $white;
  text-decoration: inherit;
}

.navmenuitem a img {
  background-color: white;
  margin-right: 4px;
  transition: 0.5s;
}

.navmenuitem:hover a {
  // color: #444444;
  // background-color: $brand-red;
  color: $highlight;
  transition: 0.5s;
}
.navmenuitem:hover {
  // background-color: brown;
}

.navmenuitem:hover {
  /* text-decoration: underline; */
  transition: 0.2s;
  //  border-bottom: 3px solid #4b1da1;
  color: $brand-red-hover;
}

ul {
  padding: 0px;
  margin: 0px;
}

li {
  // display: block;
  transition-duration: 0.5s;
}

li:hover {
  cursor: pointer;
}

ul li {
  position: relative;
}

.lang-menu-item{
  display: flex;
  text-transform: uppercase;
  p{
    margin:0;
    margin-left: 4px;
  }
}

ul li ul {
  top: 27px;
  visibility: hidden;
  opacity: 0;
  display: none;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 12px;
  right: 0;
  z-index: 500;
  right:-60px
  //  background-color: $brand-red;
  //  padding: 0px 10px;
  //  width: 400px;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  position: relative;
  // border: 1px solid black;
  //  border-width: 0px 1px 1px 1px;
  width: 140px;
  display: flex;
  align-items: center;
  background-color: $primary-dark;
  color:$white;
  margin-left:120px;
  img.flag {
    margin-left:12px;
    width: 24px;
    height: 24px;
    background-color: transparent;
    // padding: 4px;
    border-radius: 999px;
    object-fit: cover;
  }
  p {
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

ul li ul li:hover {
  background-color: $brand-red;
  padding:5px;
}

.langImage {
  background-color: #ec9a29;
  border-radius: 9999px;
  height:18px;
  width:18px;
  object-fit: cover;
}

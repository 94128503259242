@import "./components/variables.scss";





header {
  padding: 10px;
  background-color: $main-bg;
  // align-items: baseline;
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
  font-family: $font-regular !important;
  color: $black;
  letter-spacing: 1px;
  font-weight: 600;

  // grid-template-columns: 92px 120px auto auto;
  // grid-gap:20px;
  h1,
  h3 {
    margin: 0px;
  }
  h1{
    
    font-size: 24px;
    margin-right:20px;
  }
  h3{
    display: none;
    font-size:14px;
  }
  a{
    display: flex;
    align-items: center;
  }
  button.button{
    background: $brand-primary;
    height:45px;
    align-self: center;
    width: 190px;
    color: white;
    p{

    }
  }
  .nav-icon{
    font-size: 18px;
    margin-right:4px
  }
  .title-text-holder{
  // display: flex;
  align-items: baseline;
  display: none;
  }
  button.button.logout{
    // background-color: $brand-secondary;
  }
  .carbon-neutral-image{
    height:40px;
    cursor: pointer;
  }
  #headerlogo {
    max-height: 40px;
  }
  .social-bar{
    display: none;
  }
  @media (min-width: $tablet) {
    .title-text-holder{
    display: flex;
    flex-direction: column;
  }
}
.header-wrapper{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: 'row';
  display: flex;
  justify-content: space-between;
}
@media (min-width: $desktop) {
      h3{
        display: block;
      }
    .social-bar{
      display: block;
    }
  }
  .header-button-container{
    display: flex;
  }
}

button.button.secondary{
  margin-right:0;
}


.about-us {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width: 0;
  min-height: calc(100vh - 230px);
  img {
    margin-left: auto;
    margin-right: auto;
    float: right;
    margin: 20px;
  }
}

.about-us-wrapper{
  padding-bottom: 36px;
  background-color: $main-bg;
}
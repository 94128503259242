@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);



body {
  margin: 0;
  padding: 0;
  /* font-family: 'Comfortaa', cursive; */
  font-family: 'sarosregular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
@font-face {
  font-family: "ralewayregular";
  src: url("/fonts/raleway-regular-webfont.woff2") format("woff2"), url("/fonts/raleway-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sarosregular";
  src: url("/fonts/saros-regular-webfont.woff2") format("woff2"), url("/fonts/saros-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sarosregular";
  src: url("/fonts/saros-regular-webfont.woff2") format("woff2"), url("/fonts/saros-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
button[disabled] {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

button.button {
  padding: 10px 20px;
  border: 2px solid #36827F;
  border-radius: 5px;
  color: #36827F;
  font-family: "DM Sans", sans-serif;
  margin: 0 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.button[disabled] {
  background-color: #a9a9a9;
  cursor: not-allowed;
  color: #fff;
  border: none;
}
button.button.disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}
button.button:hover:not([disabled]):not(.disabled):not(.secondary):not(.third) {
  color: #fff;
  background-color: #36827F;
  transition: 0.25s;
}
button.button.dataInputChildBox {
  border-radius: 0 5px 5px 0;
  margin-right: 0;
}
button.button div {
  display: flex;
  justify-content: center;
  align-items: center;
}
button.button p {
  margin: 0;
  display: flex;
  align-items: center;
}
button.button img {
  margin-left: 8px;
}

button.secondary {
  border: 2px solid #43aa8b;
  color: #43aa8b;
  transition: 0.25s;
}
button.secondary:hover {
  transition: 0.25s;
  color: #fff;
  background-color: #43aa8b;
}

button.third {
  border: 2px solid #464D77;
  color: #464D77;
  transition: 0.25s;
}
button.third:hover {
  transition: 0.25s;
  color: #fff;
  background-color: #464D77;
}

.warning {
  color: #36827F;
}

.footnote {
  font-size: 12px;
}

.blue-text {
  color: #464D77;
}

header {
  padding: 10px;
  background-color: #E9F1F7;
  font-family: "DM Sans", sans-serif !important;
  color: #444444;
  letter-spacing: 1px;
  font-weight: 600;
}
header h1,
header h3 {
  margin: 0px;
}
header h1 {
  font-size: 24px;
  margin-right: 20px;
}
header h3 {
  display: none;
  font-size: 14px;
}
header a {
  display: flex;
  align-items: center;
}
header button.button {
  background: #36827F;
  height: 45px;
  align-self: center;
  width: 190px;
  color: white;
}
header .nav-icon {
  font-size: 18px;
  margin-right: 4px;
}
header .title-text-holder {
  align-items: baseline;
  display: none;
}
header .carbon-neutral-image {
  height: 40px;
  cursor: pointer;
}
header #headerlogo {
  max-height: 40px;
}
header .social-bar {
  display: none;
}
@media (min-width: 768px) {
  header .title-text-holder {
    display: flex;
    flex-direction: column;
  }
}
header .header-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: "row";
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  header h3 {
    display: block;
  }
  header .social-bar {
    display: block;
  }
}
header .header-button-container {
  display: flex;
}

button.button.secondary {
  margin-right: 0;
}

.floatLeft .inputArea {
  float: left;
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .floatLeft .inputArea {
    flex-direction: row;
  }
}
.floatLeft .inputArea textarea {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #36827F;
}
@media (min-width: 768px) {
  .floatLeft .inputArea textarea {
    resize: none;
    flex: 5 1;
    clear: both;
    outline: none;
    height: 120px;
    background-color: #fff;
    border: 2px solid #36827F;
    border-radius: 5px 0 0 5px;
    padding: 16px;
  }
}
.floatLeft .inputArea textarea::placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.floatLeft .inputArea textarea::-webkit-input-placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.floatLeft .inputArea textarea:-moz-placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.floatLeft .inputArea textarea::-moz-placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.floatLeft .inputArea textarea:-ms-input-placeholder {
  color: #e7e7e7;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
}
.floatLeft .inputArea button {
  background-color: #36827F;
  border-width: 0px;
  font-family: "DM Sans", sans-serif;
  cursor: pointer;
  color: #f5fff3;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  .floatLeft .inputArea button {
    flex: 1 1;
    height: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}
.floatLeft .inputArea button:focus {
  outline: 0;
}
.floatLeft .inputArea button h3 {
  font-size: 12px;
}
.floatLeft input::-moz-focus-inner {
  padding: 0;
  border: 0;
  margin-top: -2px;
  line-height: 17px;
}

.financials {
  font-family: "DM Sans", sans-serif;
  width: 100%;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: end;
}
.financials input,
.financials select {
  padding: 4px;
  margin-left: 16px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.financials input {
  border: 2px solid #36827F;
  padding: 8px;
  border-radius: 3px;
}
.financials input::placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.financials input::-webkit-input-placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.financials input:-moz-placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.financials input::-moz-placeholder {
  color: #a9a9a9;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}
.financials input:-ms-input-placeholder {
  color: #e7e7e7;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
}
.financials .fare-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
}
.financials label {
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  align-items: center;
}
.financials p {
  min-width: 40px;
  margin: 0;
  text-align: left;
}

.fare-summary {
  font-family: ralewayregular;
}
.fare-summary p {
  margin: 4px 0;
  font-size: 14px;
  text-align: left;
}
.fare-summary p.fare-totals {
  font-weight: 700;
}

.formOptions {
  min-width: 200px;
  min-height: 400px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border: 2px solid #464D77;
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 24px;
  float: right;
  font-family: "DM Sans", sans-serif;
  border-radius: 5px;
  padding: 0 10px 10px 10px;
}

h4 {
  margin: 0px;
  text-decoration: underline;
}

fieldset {
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.displayOption label {
  position: relative;
}

.displayOption label::before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid;
  margin-right: 5px;
  margin-bottom: -3px;
  border-radius: 0px;
  position: absolute;
  left: 5px;
  top: 7px;
}

.displayOption label::after {
  content: "";
  display: inline-block;
  height: 4px;
  width: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  color: #36827F;
  transform: rotate(-45deg);
  position: absolute;
  left: 7px;
  top: 10px;
}

/* to show and hide the tickes on the checkboxes and radio  */
.displayOption input[type=checkbox] + label::after, .displayOption input[type=radio] + label::after {
  content: none;
}

.displayOption input[type=checkbox]:checked + label::after, .displayOption input[type=radio]:checked + label::after {
  content: "";
}

.displayOption input[type=checkbox]:checked + label, .displayOption input[type=radio]:checked + label {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #464D77;
  font-size: 12px;
  margin-top: 3px;
  cursor: pointer;
  display: inline-block;
  text-align: left;
  padding-left: 24px;
  color: white;
  line-height: 18px;
  font-weight: 600;
  border-radius: 5px;
}

input[type=checkbox], input[type=radio] {
  position: absolute;
  left: -9999px;
}

.displayOption {
  display: flex;
}

.displayOption input[type=checkbox] + label, .displayOption input[type=radio] + label {
  padding: 5px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  margin-top: 3px;
  cursor: pointer;
  display: inline-block;
  text-align: left;
  padding-left: 24px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #444444;
}

.displayOption input[type=checkbox] + label:hover, .displayOption input[type=radio] + label:hover, .fieldset input[type=radio] + label:hover {
  background-color: #06d6a0;
  transition: 0.2s;
}

.fieldset input[type=radio] + label {
  border: 1px solid #444444;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.fieldset input[type=radio]:checked + label {
  border: 1px solid #444444;
  padding: 5px;
  background-color: #464D77;
  color: white;
  border-radius: 5px;
}

.multioptionscontainer {
  padding: 5px 5px 10px 5px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Open Sans", sans-serif;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 3px;
  cursor: pointer;
  display: block;
  text-align: center;
}

.multioptionsbreak {
  margin-bottom: 10px;
}

.advertisingBox {
  position: static;
  border: 1px solid white;
  margin-top: 30px;
  min-height: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  display: inline-block;
}

.blurb {
  width: 100%;
  color: #444444;
  box-sizing: border-box; /* fit parent width */
  resize: none;
  flex: 5 1;
  clear: both;
  outline: none;
  background-color: rgba(255, 255, 255, 0.75);
  border: 2px solid #36827F;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  padding: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.blurb p {
  text-align: left;
}
.blurb img {
  max-width: 100%;
}
.blurb pre {
  font-size: 6px;
}
@media (min-width: 768px) {
  .blurb pre {
    font-size: 12px;
  }
}

.nav-wrapper {
  background-color: #193d3b;
}

nav {
  height: 40px;
  background-color: #193d3b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 20px;
  color: #e7e7e7;
  font-family: "DM Sans", sans-serif;
  position: relative;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /*
  * Just a quick hamburger
  */
  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  /*
  * And let's slide it in from the left
  */
}
nav .nav-icon {
  font-size: 18px;
  margin-right: 4px;
}
nav #menuToggle {
  display: block;
  position: relative;
  top: 0px;
  left: 10px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}
@media (min-width: 768px) {
  nav #menuToggle {
    width: 100%;
    left: 0;
    position: static;
  }
}
nav #menuToggle > input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}
@media (min-width: 768px) {
  nav #menuToggle > input {
    display: none;
  }
}
nav #menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
@media (min-width: 768px) {
  nav #menuToggle span {
    display: none;
  }
}
nav #menuToggle span:first-child {
  transform-origin: 0% 0%;
}
nav #menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
nav #menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}
nav #menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
nav #menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
nav #menu {
  position: absolute;
  width: 100vw;
  margin: -35px 0 0 -50px;
  padding: 50px;
  padding-top: 40px;
  background: #193d3b;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
@media (min-width: 768px) {
  nav #menu {
    margin: 0;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: static;
    transform: unset;
    transition: unset;
    margin: 0;
    padding: 0;
  }
}
nav #menu li {
  padding: 10px 0;
}
nav #menuToggle input:checked ~ ul {
  transform: none;
}
nav .social-bar {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 20px;
  align-items: center;
}
nav .social-bar a {
  display: flex;
}

.navmenuitem a {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: #fff;
  text-decoration: inherit;
}

.navmenuitem a img {
  background-color: white;
  margin-right: 4px;
  transition: 0.5s;
}

.navmenuitem:hover a {
  color: #FAFF7F;
  transition: 0.5s;
}

.navmenuitem:hover {
  /* text-decoration: underline; */
  transition: 0.2s;
  color: #06d6a0;
}

ul {
  padding: 0px;
  margin: 0px;
}

li {
  transition-duration: 0.5s;
}

li:hover {
  cursor: pointer;
}

ul li {
  position: relative;
}

.lang-menu-item {
  display: flex;
  text-transform: uppercase;
}
.lang-menu-item p {
  margin: 0;
  margin-left: 4px;
}

ul li ul {
  top: 27px;
  visibility: hidden;
  opacity: 0;
  display: none;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 12px;
  right: 0;
  z-index: 500;
  right: -60px;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  position: relative;
  width: 140px;
  display: flex;
  align-items: center;
  background-color: #193d3b;
  color: #fff;
  margin-left: 120px;
}
ul li ul li img.flag {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 999px;
  object-fit: cover;
}
ul li ul li p {
  text-align: center;
  margin: 0;
  padding: 0;
}

ul li ul li:hover {
  background-color: #464D77;
  padding: 5px;
}

.langImage {
  background-color: #ec9a29;
  border-radius: 9999px;
  height: 18px;
  width: 18px;
  object-fit: cover;
}

.resultsbox {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  flex: 5 1;
  clear: both;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: 2px solid #36827F;
  padding: 10px;
  border-radius: 0 5px 5px 5px;
}

span.operatedBy {
  text-transform: capitalize;
}

.lineOne {
  font-weight: 600;
}

.selectbutton {
  border-radius: 5px 5px 0px 0px;
  border: 4px solid #36827F;
  padding: 5px;
  max-width: 50%;
  border-bottom-width: 0px;
  cursor: pointer;
  background-color: #36827F;
  color: #fff;
}

#selectable {
  margin-top: 0;
}

.row {
  display: flex;
  flex-direction: column;
  font-family: ralewayregular;
  font-size: 14px;
}

.row:last-child .transit {
  display: none;
}

.row:last-child .main-content .image-container {
  margin-bottom: 0;
}

.main-content {
  display: flex;
}

.transit {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
}

.result-text {
  text-align: left;
}
.result-text p {
  margin: 0;
  padding: 0;
}

.image-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.image-container img {
  object-fit: scale-down;
  width: 75px;
  max-height: 35px;
}

@font-face {
  font-family: "sarosregular";
  src: url("/fonts/saros-regular-webfont.woff2") format("woff2"), url("/fonts/saros-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
button[disabled] {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

button.button {
  padding: 10px 20px;
  border: 2px solid #36827F;
  border-radius: 5px;
  color: #36827F;
  font-family: "DM Sans", sans-serif;
  margin: 0 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.button[disabled] {
  background-color: #a9a9a9;
  cursor: not-allowed;
  color: #fff;
  border: none;
}
button.button.disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}
button.button:hover:not([disabled]):not(.disabled):not(.secondary):not(.third) {
  color: #fff;
  background-color: #36827F;
  transition: 0.25s;
}
button.button.dataInputChildBox {
  border-radius: 0 5px 5px 0;
  margin-right: 0;
}
button.button div {
  display: flex;
  justify-content: center;
  align-items: center;
}
button.button p {
  margin: 0;
  display: flex;
  align-items: center;
}
button.button img {
  margin-left: 8px;
}

button.secondary {
  border: 2px solid #43aa8b;
  color: #43aa8b;
  transition: 0.25s;
}
button.secondary:hover {
  transition: 0.25s;
  color: #fff;
  background-color: #43aa8b;
}

button.third {
  border: 2px solid #464D77;
  color: #464D77;
  transition: 0.25s;
}
button.third:hover {
  transition: 0.25s;
  color: #fff;
  background-color: #464D77;
}

.warning {
  color: #36827F;
}

.footnote {
  font-size: 12px;
}

.blue-text {
  color: #464D77;
}

table tr td {
  border: 1px solid black;
}
table tr td img {
  object-fit: scale-down;
  width: 75px;
  max-height: 35px;
}
table tr th {
  border: 1px solid black;
  text-transform: capitalize;
}

table {
  border-collapse: collapse;
  font-size: 14px;
  box-sizing: border-box;
}
table td, table th {
  border: 1px solid #64706c;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #F5F5F5;
}

.margin-table {
  padding: 15px;
}

.no-margin {
  margin-top: 0 !important;
}

.operated-by {
  text-transform: capitalize;
}

.footer {
  width: 100%;
  height: 100px;
  padding: 40px;
  box-sizing: border-box;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
.footer ul {
  display: flex;
}
.footer ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer .social-bar {
  display: none;
}
@media (min-width: 768px) {
  .footer .social-bar {
    display: unset;
  }
}
.footer .footer-lang {
  display: none;
}
@media (min-width: 768px) {
  .footer .footer-lang {
    display: flex;
  }
}

.footer-wrapper {
  background-color: #36827F;
}

.gdpr {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #444444;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  color: #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1000;
}
.gdpr p,
.gdpr a {
  font-size: 14px;
}
.gdpr h1 {
  font-size: 16px;
}
.gdpr button {
  height: 30px;
  width: 189px;
  border: 0;
  border-radius: 5px;
  background-color: #36827F;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin: 20px;
}
@media (min-width: 1024px) {
  .gdpr {
    flex-direction: row;
  }
  .gdpr p,
  .gdpr a {
    font-size: 12px;
  }
  .gdpr button {
    margin: 0;
  }
}

.loadcontainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.bar {
  width: 50%;
  height: 20px;
  border: 1px solid #2980b9;
  border-radius: 3px;
  background-image: repeating-linear-gradient(-45deg, #2980b9, #2980b9 11px, #eee 10px, #eee 20px);
  background-size: 28px 28px;
  animation: move 0.5s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 28px 0;
  }
}
.names-box {
  font-family: "Raleway", sans-serif;
  text-align: left;
  margin: 10px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
}

.transit {
  text-align: left;
  color: #888888;
}

.transitwarning {
  color: red;
  font-weight: 600;
}

.invalid-page-link {
  color: #36827F;
  text-decoration: underline;
}

.blog-header {
  width: 100%;
  height: 200px;
  background-image: url(/images/blogheader.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.blog {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
ul.blog li.blog-article {
  padding: 20px;
  background-color: #e7e7e7;
  border-radius: 5px;
  list-style: none;
  margin: 0;
}
ul.blog li.blog-article:hover {
  background-color: lightgray;
  transition: 0.25s;
  box-shadow: none;
}
ul.blog li.blog-article img {
  width: 100%;
}

.blog-container {
  padding: 24px;
  border: 2px solid #36827F;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  background-color: white;
  text-align: center;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width: 0;
  min-height: calc(100vh - 245px);
  box-sizing: border-box;
}

.blogtitleimage {
  width: 100%;
}

.blog-wrapper {
  padding-bottom: 36px;
  background-color: #E9F1F7;
}

.ad-page-container {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 11111; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad-page-container .adblockmessage {
  width: 50vw;
  height: 30vh;
  background-color: white;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner {
  width: 100%;
  padding: 7px;
  background-color: white;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  color: #444444;
  font-size: 14px;
  text-align: left;
}
.banner a {
  color: #36827F;
  font-weight: 600;
  text-decoration: underline;
}

.about-us {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width: 0;
  min-height: calc(100vh - 230px);
}
.about-us img {
  margin-left: auto;
  margin-right: auto;
  float: right;
  margin: 20px;
}

.about-us-wrapper {
  padding-bottom: 36px;
  background-color: #E9F1F7;
}

.carbon-offset {
  padding: 0;
}
.carbon-offset .carbon-header {
  background: blue;
  padding: 24px;
  background-image: url("/images/carbonheader.jpg");
  height: 200px;
  background-size: 100% 100%;
}
.carbon-offset .carbon-body {
  padding: 24px;
}
.carbon-offset a {
  color: #464D77;
}

.api-intro {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width: 0;
}
.api-intro img {
  max-width: 50%;
}
.api-intro .section-title span {
  font-size: 16px;
}

.api-intro-container {
  padding-bottom: 36px;
  background-color: #E9F1F7;
}

.how-to {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width: 0;
}
.how-to .how-to-image {
  width: 100%;
}
.how-to h4 {
  margin: 20px 5px;
}

.how-to-wrapper {
  background-color: #E9F1F7;
}

.blog-ad {
  width: 100%;
  border: 1px solid black;
  display: block;
}
.article-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .article-cont {
    flex-direction: row;
  }
}

.sign-up-window {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.sign-up-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-direction: column;
  width: 84vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;
}
.sign-up-box .signup-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
.sign-up-box .signup-heading .close {
  cursor: pointer;
  width: 30px;
}
.sign-up-box .signup-heading h2 {
  margin-top: 10px;
}

.sign-up-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.signup, .signin {
  margin: 10px;
  flex: 1 1;
  align-content: stretch;
  padding: 20px;
}
.signup h2, .signup h3:not(.warning), .signup h6, .signup label, .signup li, .signup p:not(.icon-signin-red):not(.icon-signup-green), .signin h2, .signin h3:not(.warning), .signin h6, .signin label, .signin li, .signin p:not(.icon-signin-red):not(.icon-signup-green) {
  color: #444444;
}
.signup p, .signin p {
  text-align: left;
}
.signup h6, .signin h6 {
  margin-bottom: 0;
}
.signup .underline, .signin .underline {
  border-bottom: 1px solid darkgray;
  padding-bottom: 10px;
}
.signup h6.password-reset, .signin h6.password-reset {
  cursor: pointer;
}

.signin {
  border: 2px solid #464D77;
  border-radius: 5px;
}
.signin form {
  font-family: "DM Sans", sans-serif;
}
.signin form button {
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
  margin-left: 0;
}
.signin form label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  padding: 10px 0;
  box-sizing: border-box;
}
.signin form input {
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding: 0 6px;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  margin-top: 12px;
}

.signup {
  border: 2px solid #43aa8b;
  border-radius: 5px;
}
.signup a {
  width: 100%;
  background-color: #43aa8b;
  padding: 10px;
  margin-top: 30px;
  float: left;
  box-sizing: border-box;
  cursor: pointer;
}
.signup ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 12px;
  margin-bottom: 12px;
}
.signup ul li:before {
  content: "\2022   ";
}
.signup button {
  width: 100%;
  color: #43aa8b;
  padding: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.signup button p.icon-signup-green {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.sign-up-page .loadcontainer {
  margin-top: 40px;
  width: 100%;
}
.sign-up-page .loadcontainer .bar {
  width: 100%;
}
.sign-up-page .password-warning {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.sign-up-page .whysignup {
  border: 2px solid #36827F;
  padding: 20px;
}
.sign-up-page .whysignup h2 {
  margin-top: 0;
}
.sign-up-page .whysignup h4 {
  text-decoration: none;
  margin: 8px;
}
.sign-up-page .whysignup ul {
  border-top: 2px solid #464D77;
  padding: 12px 0;
}
.sign-up-page .whysignup ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.signup-activation-page {
  flex: 1 1;
}
.signup-activation-page button.button {
  width: 100%;
  box-sizing: border-box;
  margin: 20px 0 0 0;
}
.signup-activation-page .img-stamps {
  background: red;
  background: url(/images/stamps.svg) no-repeat;
  justify-self: stretch;
  background-size: cover;
  background-position: center;
}
.signup-activation-page .sign-up-activation-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  height: 60%;
}
.signup-activation-page .sign-up-activation-content form {
  border: 2px solid #464D77;
  padding: 20px;
}
.signup-activation-page .sign-up-activation-content form h3:not(.warning) {
  display: none;
}
.signup-activation-page .sign-up-activation-content form h3.warning {
  margin: 0;
}
.signup-activation-page .sign-up-activation-content form .models {
  display: flex;
  flex-direction: column;
}
.signup-activation-page .sign-up-activation-content form .models label {
  display: flex;
  flex-direction: column;
}
.signup-activation-page .sign-up-activation-content form .models input {
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding: 0 6px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.sign-up-page,
.signup-activation-page,
.members {
  /* Hide the browser's default radio button */
}
.sign-up-page img,
.signup-activation-page img,
.members img {
  max-height: 240px;
}
.sign-up-page .form-container,
.signup-activation-page .form-container,
.members .form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.sign-up-page .form-container form,
.signup-activation-page .form-container form,
.members .form-container form {
  display: flex;
  flex-direction: column;
  border: 2px solid #43aa8b;
  padding: 20px;
}
.sign-up-page .form-container form label,
.signup-activation-page .form-container form label,
.members .form-container form label {
  flex: 1 1;
  display: inline-grid;
  margin-top: 15px;
  font-size: 20px;
}
.sign-up-page .form-container form input,
.signup-activation-page .form-container form input,
.members .form-container form input {
  box-sizing: border-box;
  height: 36px;
  padding: 0 6px;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
}
.sign-up-page .form-container form button,
.signup-activation-page .form-container form button,
.members .form-container form button {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  background-color: #43aa8b;
  padding: 10px;
  border: none;
  outline: none;
  font-family: "DM Sans", sans-serif;
  color: white;
  font-size: 16px;
  line-height: 20px;
  margin-top: 30px;
  cursor: pointer;
}
.sign-up-page .form-container form button[disabled],
.signup-activation-page .form-container form button[disabled],
.members .form-container form button[disabled] {
  background-color: #a9a9a9;
  cursor: not-allowed;
}
.sign-up-page .api-choice-container,
.signup-activation-page .api-choice-container,
.members .api-choice-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  flex: 1 1;
}
.sign-up-page .api-choice-container input,
.signup-activation-page .api-choice-container input,
.members .api-choice-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.mail-sent-page {
  padding: 60px;
}

.member-content {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.members a {
  text-decoration: underline;
  cursor: pointer;
  color: #36827F;
}

.member-content-area {
  border: 2px solid #444444;
  padding: 10px;
}
.member-content-area a,
.member-content-area .action {
  color: #36827F;
  text-decoration: underline;
  cursor: pointer;
}
.member-content-area .field {
  display: flex;
  align-items: center;
}
.member-content-area .field p {
  margin-left: 10px;
}

.member-menu li {
  background: #464D77;
  margin-bottom: 10px;
  list-style: none;
  padding: 5px;
  color: white;
}
.member-menu li:hover {
  box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
  transition: 0.25s;
}
.member-menu li.active {
  background: #444444;
  padding-right: 0;
}
.member-menu li:last-child {
  margin-bottom: 0;
}
.member-menu li.icon-logout {
  display: flex;
  align-items: center;
}

.private-key-container {
  display: flex;
}
.private-key-container p:first-child {
  margin-right: 4px;
}
.private-key-container p.warning {
  cursor: pointer;
}

.json-response {
  background: #ededed;
}

.cancel-plan-box {
  margin: 10px 0;
}

.members table td.actions a {
  float: left;
  clear: both;
  margin-bottom: 5px;
}
.members table td.actions a:last-child {
  margin-bottom: 0;
}

.memberChangeSubscription.modal-content {
  border: 2px solid #464D77;
  box-shadow: 4px 4px 8px -6px rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 24px;
}

/* Modal Content */
.modal-content {
  position: fixed;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.edit-details label {
  height: 42px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.edit-details input {
  color: #bebebe;
  border: 0;
  font-size: 24px;
  padding-left: 16px;
  border-bottom: 1px solid grey;
  outline: none;
}

.members-contact-us-form .form-top-line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  box-sizing: border-box;
}
.members-contact-us-form .form-top-line input {
  width: 100%;
}
.members-contact-us-form .form-top-line label {
  box-sizing: border-box;
}
.members-contact-us-form textarea {
  width: 100%;
  margin-top: 20px;
  resize: none;
  border: 2px solid #43aa8b;
  box-sizing: border-box;
  font-size: 24px;
  padding: 10px;
}
.members-contact-us-form input {
  color: #bebebe;
  border: 0;
  font-size: 24px;
  padding-left: 16px;
  border-bottom: 1px solid grey;
  outline: none;
  box-sizing: border-box;
}

.invoices p {
  margin: 0;
}
.invoices ul li {
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  list-style: none;
  display: grid;
  grid-template-columns: auto 1fr;
}
.invoices ul li:hover {
  background: #ededed;
}
.invoices .bold {
  font-weight: 700;
}
.invoices .download-icon {
  height: 100%;
  width: auto;
  margin-right: 20px;
}

.edit-button-container {
  margin: 24px 0;
  display: flex;
}

.cancel-buttons-container {
  display: flex;
}

.update-card p {
  margin: 0;
}
.update-card form {
  width: 50%;
  max-width: 600px;
  border: 2px solid #464D77;
  padding: 20px;
}
.update-card .card-element.StripeElement {
  border-bottom: 1px solid #888888;
}
.update-card input.cardholder-name {
  margin-bottom: 20px;
}
.update-card input::placeholder {
  font-size: 14px;
}
.update-card .loadcontainer {
  margin-top: 20px;
  width: 100%;
}
.update-card .loadcontainer .bar {
  width: 100%;
}

.summary-container {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  grid-gap: 16px;
}
.summary-container .arrow {
  background-image: url(/images/right-arrow.svg);
  min-height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.change-plan-confirmation p,
.change-plan-confirmation h1,
.change-plan-confirmation h5 {
  margin: 0;
}
.change-plan-confirmation h3 {
  margin-top: 24px;
  margin-bottom: 16px;
}
.change-plan-confirmation .change-note {
  margin-top: 16px;
}
.change-plan-confirmation .change-plan-box {
  border: 2px solid #464D77;
  border-radius: 5px;
  padding: 16px;
  flex: 1 1;
}
.change-plan-confirmation .current-plan,
.change-plan-confirmation .new-plan {
  display: flex;
  flex-direction: column;
}
.change-plan-confirmation .loadcontainer {
  margin-top: 16px;
}

.icon-logout-green:after {
  margin-left: 10px;
  background-image: url(/images/icons/logout-green.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-logout:after {
  margin-left: 10px;
  background-image: url(/images/icons/logout.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-members:after {
  margin-left: 10px;
  background-image: url(/images/icons/settings-red.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-save:after {
  margin-left: 10px;
  background-image: url(/images/icons/save-red.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-cancel-green:after {
  margin-left: 10px;
  background-image: url(/images/icons/cancel-green.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-payment-blue:after {
  margin-left: 10px;
  background-image: url(/images/icons/payment-blue.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-submit-red:after {
  margin-left: 10px;
  background-image: url(/images/icons/icon-submit-red.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-checkout-red:after {
  margin-left: 10px;
  background-image: url(/images/icons/checkout-red.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-signin-red:after {
  margin-left: 10px;
  background-image: url(/images/icons/log-in-red.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.icon-signup-green:after {
  margin-left: 10px;
  background-image: url(/images/icons/signup-green.svg);
  content: "";
  background-size: cover;
  display: block;
  width: 12px;
  height: 12px;
}

.stripeSignup {
  border: 2px solid #36827F;
  display: flex;
  flex-direction: column;
}
.stripeSignup label {
  height: 42px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.stripeSignup input {
  color: #BEBEBE;
  border: 0;
  font-size: 24px;
  padding-left: 16px;
  border-bottom: 1px solid grey;
  outline: none;
}
.stripeSignup input#stripe-name {
  width: 100%;
  padding: 0;
  margin: 25px 0;
  padding: 20px;
  border: 2px solid lightgray;
  box-sizing: border-box;
  font-size: 14px;
  color: black;
}
.stripeSignup main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.stripeSignup header {
  display: grid;
  grid-template-columns: 1fr 5fr;
}
.stripeSignup header img {
  height: 50px;
}
.stripeSignup h1, .stripeSignup h2, .stripeSignup h5, .stripeSignup h3, .stripeSignup p {
  margin: 0;
}
.stripeSignup .summary {
  border: 2px solid #43aa8b;
  margin: 0 10px 10px 0;
  padding: 20px;
}
.stripeSignup .summary h2 {
  margin-bottom: 24px;
}
.stripeSignup .loadcontainer {
  margin-top: 25px;
}
.stripeSignup .loadcontainer .bar {
  width: 100%;
}
.stripeSignup .cc-form {
  border: 2px solid #464D77;
  margin: 0 0 10px 10px;
  padding: 20px;
}
.stripeSignup .card-element {
  padding: 20px;
  border: 2px solid lightgray;
}
.stripeSignup button.button {
  width: 100%;
  box-sizing: border-box;
  margin: 24px 0 16px 0;
}
.stripeSignup .summary-content {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 15px;
  align-items: center;
}
.stripeSignup .grid-span {
  grid-column: 1/span 2;
}

.password-reset {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  cursor: pointer;
}
.password-reset h3 {
  margin: 0;
}
.password-reset .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.password-reset .left-box {
  border: 2px solid #464D77;
  padding: 24px;
}
.password-reset label {
  display: flex;
  flex-direction: column;
}
.password-reset label#conf-pw-label {
  padding-top: 24px;
}
.password-reset input {
  color: #bebebe;
  border: 0;
  font-size: 24px;
  padding-left: 16px;
  border-bottom: 1px solid grey;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 32px;
}
.password-reset input::placeholder {
  color: #e7e7e7;
}
.password-reset button.button {
  width: 100%;
  margin: 20px 0 0 0;
}
.password-reset form {
  border: 2px solid #464D77;
  padding: 24px;
  margin-top: 24px;
}

.signup-activation-page {
  display: flex;
  flex-direction: column;
}
.signup-activation-page .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.signup-activation-page .content form {
  border: 2px solid #464D77;
  padding: 20px;
}
.signup-activation-page .content label {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 0;
}
.signup-activation-page .content input {
  color: #bebebe;
  border: 0;
  font-size: 24px;
  padding-left: 16px;
  border-bottom: 1px solid grey;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}
.signup-activation-page .content input::placeholder {
  color: #dcdcdc;
}
.signup-activation-page .content button.button {
  width: 100%;
  margin: 20px 0 0 0;
}
.signup-activation-page .content #conf-pw-label {
  padding-top: 20px;
  margin-top: 20px;
}
.signup-activation-page p {
  margin: 0;
  font-size: 12px;
}
.signup-activation-page .loadcontainer {
  margin-top: 40px;
}
.signup-activation-page .loadcontainer .bar {
  width: 100%;
}

.hidden {
  display: none;
}

fieldset.currency {
  height: 40px;
  margin-bottom: 10px;
}
fieldset.currency input + label {
  padding: 10px 20px;
  margin: 0px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
}
fieldset.currency input + label:hover {
  border: 2px solid #464D77;
}
fieldset.currency input:checked + label {
  border: 2px solid #36827F;
}
fieldset.currency .currency-choice {
  position: relative;
}
fieldset.currency .currency-choice input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.plans-radio .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
.plans-radio .grid-container .plan-option {
  display: flex;
  flex-direction: column;
}
.plans-radio .grid-container .plan-option label {
  border-radius: 5px;
  padding: 16px;
  font-size: 14px;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
}
.plans-radio .grid-container .plan-option label:hover {
  background-color: #f9f9f9;
}
.plans-radio .grid-container .plan-option .current-plan {
  border: 2px solid #43aa8b;
  padding: 15px;
}
.plans-radio .grid-container .plan-option input:checked + label {
  border: 2px solid #36827F;
  background-color: #f9f9f9;
  padding: 15px;
}

.plan-head {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
}

.plan-calls {
  display: block;
  font-size: 16px;
}

.plan-cost {
  display: block;
  font-size: 16px;
}

body,
html {
  height: 100%;
}

.content-wrapper {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.body {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#root {
  display: flex;
  flex-direction: column;
}

.container {
  width: 96%;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr auto;
}
.container form {
  width: 100%;
  display: flex;
}

.floatLeft {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.floatRight {
  display: none;
}
@media (min-width: 768px) {
  .floatRight {
    display: block;
    flex: 1 1;
  }
}

.App {
  text-align: center;
  background-color: #E9F1F7;
  height: 100%;
  min-height: calc(100vh - 210px);
}

.shadow {
  box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
  transition: 0.25s;
}

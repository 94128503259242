.carbon-offset{
    padding:0;
    h2{
        // margin:0;
    }
    .carbon-header{
        background:blue;
        padding:24px;
        background-image: url('/images/carbonheader.jpg');
        height:200px;
        background-size:100% 100%;

    }
    .carbon-body{
        padding:24px;
    }
    a{
        color:$brand-red;
    }
}
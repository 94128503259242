
.advertisingBox{
  // float:left;
  position:static;
border: 1px solid white;
  margin-top:30px;
  min-height:90px;
  // width:100%;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:30px;
  display: inline-block;
}
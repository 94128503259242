.icon-logout-green {
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/logout-green.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}

.icon-logout {
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/logout.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}

.icon-members {
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/settings-red.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}


.icon-save {
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/save-red.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}

.icon-cancel-green {
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/cancel-green.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}

.icon-payment-blue {
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/payment-blue.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}
.icon-submit-red{
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/icon-submit-red.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}


.icon-checkout-red{
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/checkout-red.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}

.icon-api-red{
  // &:after {
  //   margin-left: 10px;
  //   background-image: url(/images/icons/api-red.svg);
  //   content: "";
  //   background-size: cover;
  //   display: block;
  //   width: 12px;
  //   height: 12px;
  // }
}


.icon-signin-red{
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/log-in-red.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}

.icon-signup-green{
  &:after {
    margin-left: 10px;
    background-image: url(/images/icons/signup-green.svg);
    content: "";
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
  }
}
.formOptions{
  min-width:200px;
  min-height:400px;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
  // background-color: chocolate;
  border: 2px solid $brand-red;
  background-color: rgba(255,255,255,0.8);
  margin-left:24px;
  float:right;
  font-family:$font-regular;
  border-radius: 5px;
  padding: 0 10px 10px 10px;
}

h4{
  margin:0px;
  text-decoration: underline;
}

fieldset{
  border:0px;
  margin:0px;
  padding:0px;
}

.displayOption label {
  position: relative;
  
}


.displayOption label::before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid;
  margin-right: 5px;
  margin-bottom: -3px;
  border-radius: 0px;
  position: absolute;
  left: 5px;
  top: 7px;
}

.displayOption label::after {
  content: "";
  display: inline-block;
  height: 4px;
  width: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  color: $brand-primary;
  transform: rotate(-45deg);
  position: absolute;
  left: 7px;
  top: 10px;
}
/* to show and hide the tickes on the checkboxes and radio  */
.displayOption input[type="checkbox"] + label::after, .displayOption input[type="radio"] + label::after {
  content: none;
}

.displayOption input[type="checkbox"]:checked + label::after,.displayOption input[type="radio"]:checked + label::after {
  content: "";
}


.displayOption input[type="checkbox"]:checked + label,.displayOption input[type="radio"]:checked + label{
  width:100%;
  margin-left: auto;
  margin-right: auto;
  background-color: $brand-red;
  font-size:12px;
  margin-top:3px;
  cursor: pointer;
  display:inline-block;
  text-align: left;
  padding-left: 24px;
  color:white;
  line-height:18px;
  font-weight:600;
  border-radius:5px;
} 

input[type="checkbox"], input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.displayOption{
  display: flex;
}

//unselected display options
.displayOption input[type="checkbox"] + label,.displayOption input[type="radio"] + label{
  padding: 5px;
  width:100%;
  margin-left: auto;
  margin-right: auto;
  font-size:12px;
  margin-top:3px;
  cursor: pointer;
  display:inline-block;
  text-align: left;
  padding-left: 24px;
  // background-color: $unselected;
  line-height:18px;
  font-weight:600;
  border-radius:5px;
  border: 1px solid $black
}

.displayOption input[type="checkbox"] + label:hover,.displayOption input[type="radio"] + label:hover, .fieldset input[type="radio"] + label:hover{
  background-color:$brand-red-hover;
  transition: 0.2s;

}

.fieldset input[type="radio"] + label{
  border: 1px solid $black;
  padding: 5px;
  margin:5px;
  cursor: pointer;
  // background-color: $unselected;
  border-radius:5px;
}
.fieldset input[type="radio"]:checked + label{
  border: 1px solid $black;
  padding: 5px;
  background-color: $brand-red;
  color:white;
  border-radius:5px;
}

.multioptionscontainer{
padding: 5px 5px 10px 5px;
box-sizing: border-box;
width:100%;
margin-left: auto;
margin-right: auto;
font-family: 'Open Sans', sans-serif;
border-radius:5px;
font-size:12px;
margin-top:3px;
cursor: pointer;
display:block;
text-align: center;
}

.multioptionsbreak{
  margin-bottom:10px;
}


.blog-ad{
width:100%;
border: 1px solid black;
display:block;
@media (min-width: $tablet) {
  // width:50%;
}
@media (min-width: $desktop) {
  // width:20%;
}

}

.article-cont{
  width:100%;
  display: flex;
  flex-direction: column;
  @media (min-width: $tablet) {
    flex-direction: row;
  }
}
.floatLeft {
  .inputArea {
    float: left;
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
    @media (min-width: $tablet) {
      flex-direction: row;
    }

    textarea {
      width: 100%;
      // background-color: aqua;
      box-sizing: border-box;
      background-color: $white;
      border: 2px solid $brand-primary;
      // border-width: 4px 4px 0px 4px;
      @media (min-width: $tablet) {
        resize: none;
        flex: 5;
        clear: both;
        outline: none;
        height: 120px;
        background-color: $white;
        border: 2px solid $brand-primary;
        border-radius: 5px 0 0 5px;
        // border-width: 4px 0px 4px 4px;
        padding: 16px;
      }
      &::placeholder {
        color: $grey;
        font-family: $font-condensed;
        font-size: 12px;
      }
      &::-webkit-input-placeholder {
        color: $grey;
        font-family: $font-condensed;
        font-size: 12px;
      }

      &:-moz-placeholder {
        color: $grey;
        font-family: $font-condensed;
        font-size: 12px;
      }

      &::-moz-placeholder {
        color: $grey;
        font-family: $font-condensed;
        font-size: 12px;
      }

      &:-ms-input-placeholder {
        color: $grey-light;
        font-family: $font-condensed;
        font-size: 16px;
      }
    }
    button {
      background-color: $brand-primary;
      // border: 4px solid $brand-primary;
      border-width: 0px;
      font-family: $font-regular;
      cursor: pointer;
      color: #f5fff3;
      letter-spacing: 0.5px;
      @media (min-width: $tablet) {
        flex: 1;
        height: 120px;
        // border-width: 1px 1px 1px 0px;
        // border-style: solid;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // border: 4px solid $brand-primary;
        margin-left: 0;
      }
      &:focus {
        outline: 0;
      }
      h3 {
        font-size: 12px;
      }
    }
    textarea {
      // display: block;
    }
  }
  input::-moz-focus-inner {
    padding: 0;
    border: 0;
    margin-top: -2px;
    line-height: 17px;
  }
}

.financials {
  font-family: $font-regular;
  width: 100%;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: end;
  input,
  select {
    padding: 4px;
    margin-left: 16px;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
  }
  input {
    border: 2px solid $brand-primary;
    padding: 8px;
    border-radius: 3px;
    &::placeholder {
      color: $grey;
      font-family: $font-condensed;
      font-size: 12px;
    }
    &::-webkit-input-placeholder {
      color: $grey;
      font-family: $font-condensed;
      font-size: 12px;
    }

    &:-moz-placeholder {
      color: $grey;
      font-family: $font-condensed;
      font-size: 12px;
    }

    &::-moz-placeholder {
      color: $grey;
      font-family: $font-condensed;
      font-size: 12px;
    }

    &:-ms-input-placeholder {
      color: $grey-light;
      font-family: $font-condensed;
      font-size: 16px;
    }
  }
  .fare-inputs {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
  }
  label {
    margin-bottom: 4px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  p {
    min-width: 40px;
    margin: 0;
    text-align: left;
  }
}

.fare-summary {
  font-family: ralewayregular;
  p {
    margin: 4px 0;
    font-size: 14px;
    text-align: left;
    &.fare-totals {
      font-weight:700;
    }
  }
}

.footer{
  width: 100%;
  height:100px;

  // margin-top:20px;
  padding:40px;
  box-sizing: border-box;
  color: $white;
  font-family: $font-condensed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top:24px;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  ul{
    display: flex;
    li{
      list-style: none;
      padding:0;
      margin:0;
    }
  }
  .social-bar{
    display: none;
    @media (min-width: $tablet) {
    display: unset;
    }
  }
  .footer-lang{
    display: none;
    @media (min-width: $tablet) {
    display: flex;
    }
  }
}
.footer-wrapper{
  background-color: $brand-primary;
}
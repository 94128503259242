.banner{
  width:100%;
  // background-color:red;
  padding:7px;
  background-color: white;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  font-family:  $font-regular;
  color:$black;
  font-size: 14px;
  text-align: left;
  a{
    color:$brand-primary;
    font-weight: 600;
    text-decoration: underline;
  }
}
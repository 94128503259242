.blog-header{
  width:100%;
  height:200px;
  background-image: url(/images/blogheader.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.blog{
  margin-top:20px;
  width: 100%;
  // background-color:red;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap:10px;
  padding-left:10px;
  padding-right:10px;
  box-sizing: border-box;

  li.blog-article{
    padding:20px;
    background-color:$grey-light;
    border-radius:5px;
    list-style: none;
    margin:0;
    &:hover{
      background-color: lightgray;
      transition: 0.25s;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    img{
      width:100%;
    }
  }
}
.blog-container{
  padding: 24px;;
  border: 2px solid $brand-primary;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
  background-color: white;
  text-align: center;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width: 0;
  min-height: calc(100vh - 245px);
  box-sizing: border-box;
}
.blogtitleimage{
  width:100%;
}

.blog-wrapper{
  padding-bottom: 36px;
  background-color: $main-bg;
}
fieldset.currency {
  height: 40px;
  margin-bottom: 10px;
  input + label {
    padding: 10px 20px;
    margin: 0px;
    border: 1px solid $grey;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      border: 2px solid $brand-red;
    }
  }
  input:checked + label {
    border: 2px solid $brand-primary;
  }

  .currency-choice {
    position: relative;
  }
  .currency-choice input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.plans-radio{

  .grid-container{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    .plan-option{

    display: flex;
    flex-direction: column;

      label{
        border-radius:5px;
        padding:16px;
        font-size:14px;
        border: 1px solid $grey;
        box-sizing: border-box;
        &:hover{
          background-color: $grey-lighter;
        }
      }
 
      .current-plan{
        border: 2px solid $brand-secondary;
        padding:15px;

      }
      input:checked+label{
        border: 2px solid $brand-primary;
        background-color: $grey-lighter;
        padding:15px;
      }
    }
  }
}

.plan-head{
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom:16px;
}
.plan-calls{
  display: block;
  font-size: 16px;

}
.plan-cost{
  display: block;
  font-size: 16px;

}
.how-to{
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width:0;
.how-to-image{
  width:100%;
}
h4{
  margin:20px 5px;;
}

}
.how-to-wrapper{background-color: $main-bg;}
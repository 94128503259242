.ad-page-container{
 /* Height & width depends on how you want to reveal the overlay (see JS below) */   
 height: 100%;
 width: 100%;
 position: fixed; /* Stay in place */
 z-index: 11111; /* Sit on top */
 left: 0;
 top: 0;
//  background-color: rgb(0,0,0); /* Black fallback color */
 background-color: rgba(0,0,0, 0.4); /* Black w/opacity */
 overflow-x: hidden; /* Disable horizontal scroll */
//  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
display:flex;
align-items: center;
justify-content: center;
  .adblockmessage{
  width:50vw;
  height:30vh;
  background-color: white;
  border: 1px solid black;
  display:flex;
align-items: center;
justify-content: center;
flex-direction: column;
  }
}
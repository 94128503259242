.transit {
  text-align: left;
  color: $grey-dark;
}

.transitwarning{
  color:red;
  font-weight:600;
}



.blurb{
  // margin-top:30px;
  width:100%;
color:$black;
  box-sizing: border-box; /* fit parent width */
  resize: none;
  flex: 5;
  clear: both;
  outline: none;
  // height: 120px;
  background-color: rgba(255,255,255,0.75);
  border: 2px solid $brand-primary;
  border-radius:5px;
  font-family:$font-regular;
  padding:20px;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
  p{
    text-align: left;
  }
  img{
    max-width: 100%;
  }
  pre{
    font-size: 6px;
    @media (min-width: $tablet) {
      font-size: 12px;
    }
  }
}
.member-content {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.members{
  a{
    text-decoration: underline;
    cursor: pointer;
    color: $brand-primary;
  }
}

.member-content-area {
  border: 2px solid $black;
  padding: 10px;
  a,
  .action {
    color: $brand-primary;
    text-decoration: underline;
    cursor: pointer;
  }
  .field {
    display: flex;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
}

.member-menu {
  li {
    background: $brand-red;
    margin-bottom: 10px;
    list-style: none;
    padding: 5px;

    color: white;
    &:hover {
      -webkit-box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
      box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
      transition: 0.25s;
    }
    &.active {
      background: $black;
      padding-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.icon-logout {
      display: flex;
      align-items: center;
    }
  }
}

//api

.private-key-container {
  display: flex;
  p:first-child {
    margin-right: 4px;
  }
  p.warning {
    cursor: pointer;
  }
}

.json-response {
  background: #ededed;
}
.cancel-plan-box {
  margin: 10px 0;
}

//member details
.members {
  table {
    td.actions {
      a {
        float: left;
        clear: both;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.memberChangeSubscription.modal-content {
  border: 2px solid $brand-red;
  -webkit-box-shadow: 4px 4px 8px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 8px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 4px 8px -6px rgba(0, 0, 0, 0.75);
}

.modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 24px;
}

/* Modal Content */
.modal-content {
  position: fixed;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.edit-details {
  label {
    height: 42px;
    // border-right:0;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  input {
    color: #bebebe;
    border: 0;
    font-size: 24px;
    padding-left: 16px;
    border-bottom: 1px solid grey;
    outline: none;
  }
}

.members-contact-us-form {
  .form-top-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    box-sizing: border-box;
    input {
      width: 100%;
    }
    label {
      box-sizing: border-box;
    }
  }
  textarea {
    width: 100%;
    margin-top: 20px;
    resize: none;
    border: 2px solid $brand-secondary;
    box-sizing: border-box;
    font-size: 24px;
    padding: 10px;
  }
  input {
    color: #bebebe;
    border: 0;
    font-size: 24px;
    padding-left: 16px;
    border-bottom: 1px solid grey;
    outline: none;
    box-sizing: border-box;
  }
}

.invoices {
  p {
    margin: 0;
  }
  ul {
    li {
      border: 1px solid gray;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 10px;
      list-style: none;
      display: grid;
      grid-template-columns: auto 1fr;
      &:hover {
        background: #ededed;
      }
    }
  }
  .bold {
    font-weight: 700;
  }
  .download-icon {
    height: 100%;
    width: auto;
    margin-right: 20px;
  }
}

.edit-button-container {
  margin: 24px 0;
  display: flex;
}

.cancel-buttons-container{
  display: flex;
}

.update-card {
  p {
    margin: 0;
  }
  form {
    width: 50%;
    max-width: 600px;
    border: 2px solid $brand-red;
    padding: 20px;
  }
  .card-element.StripeElement {
    border-bottom: 1px solid $grey-dark;
  }
  input {
    &.cardholder-name {
      margin-bottom: 20px;
    }
    &::placeholder {
      font-size: 14px;
    }
  }
  .loadcontainer {
    margin-top: 20px;
    width: 100%;
    .bar {
      width: 100%;
    }
  }
}

.summary-container {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  grid-gap: 16px;
  .arrow {
    background-image: url(/images/right-arrow.svg);
    min-height: 20px;
    width: 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.change-plan-confirmation {
  p,
  h1,
  h5 {
    margin: 0;
  }
  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .change-note {
    margin-top: 16px;
  }
  .change-plan-box {
    border: 2px solid $brand-red;
    border-radius: 5px;
    padding: 16px;
    flex: 1;
  }
  .current-plan,
  .new-plan {
    display: flex;
    flex-direction: column;
  }
  .loadcontainer{
    margin-top:16px;
  }
}

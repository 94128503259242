// .background-blur{

//   opacity:    0.5; 
//   background: #000; 
//   width:      100%;
//   height:     100%; 
//   z-index:    10;
//   top:        0; 
//   left:       0; 
//   position:   fixed;
//   display: flex;
//   align-items: center;
//   justify-content: center;

// }

// .spinner{
//   height:75px;
//   width:75px;
//   border: 4px solid gray;
//   border-bottom-color: $brand-primary;
//   border-radius:50%;
// }
.loadcontainer{
  margin-bottom:20px;
  display: flex;
  justify-content: center;
}
.bar {
  width: 50%;
  height: 20px;
  border: 1px solid #2980b9;
  border-radius: 3px;
  background-image: 
    repeating-linear-gradient(
      -45deg,
      #2980b9,
      #2980b9 11px,
      #eee 10px,
      #eee 20px /* determines size */
    );
  background-size: 28px 28px;
  animation: move .5s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 28px 0;
  }
}
.sign-up-page {
  .loadcontainer {
    margin-top: 40px;
    width: 100%;
    .bar {
      width: 100%;
    }
  }
  .password-warning{
    font-size: 14px;
    margin:0;
    padding:0;
  }
  .whysignup{
    border: 2px solid $brand-primary;
    padding:20px;
    h2{
      margin-top:0;
    }
    h4{
      text-decoration: none;
      margin:8px;
    }
    .why-sign-up-list{
    }
    ul{
      border-top: 2px solid $brand-red;
      padding:12px 0;
      li{
        list-style: none;
        padding:0;
        margin:0;
      }
    }
    .price-list{

    }
  }
}

.signup-activation-page{
  flex:1;
  button.button{
    width:100%;
    box-sizing: border-box;
    margin:20px 0 0 0;
  }
  .img-stamps{
    background:red;
    background: url(/images/stamps.svg) no-repeat;
    // height:100%;
    // width:10%;
    justify-self:  stretch;
    background-size: cover;
    background-position: center;
  }
  .sign-up-activation-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:20px;
    height:60%;
    form{
      border: 2px solid $brand-red;
      padding:20px;
      h3:not(.warning){
        display:none;
      }
      h3.warning{
        margin:0;
      }
      .models{
        display: flex;
        flex-direction: column;
        label{
          display: flex;
          flex-direction: column;
        }
        input{
          width: 100%;
    box-sizing: border-box;
    height: 36px;
    padding: 0 6px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
        }
      }
    }
  }
}

.sign-up-page,
.signup-activation-page,
.members {
  img {
    max-height: 240px;
  }
  .form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    form {
      display: flex;
      flex-direction: column;
      border: 2px solid $brand-secondary;
      padding: 20px;
      label {
        flex: 1;
        display: inline-grid;
        margin-top: 15px;
        font-size: 20px;
      }
      input {
        box-sizing: border-box;
        height: 36px;
        padding: 0 6px;
        font-size: 20px;
        font-family: $font-regular;
      }
      button {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;
        background-color: $brand-secondary;
        padding: 10px;
        border: none;
        outline: none;
        font-family: $font-regular;
        color: white;
        font-size: 16px;
        line-height: 20px;
        margin-top: 30px;
        cursor: pointer;
        &[disabled]{
          background-color: $grey;
          cursor: not-allowed;
        }
      }
    }
  }
  .api-choice-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex:1;
  }

  /* Hide the browser's default radio button */
  .api-choice-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

}

.mail-sent-page{
  padding:60px;
}
table {
  tr {
    td {
      border: 1px solid black;
      img {
        object-fit: scale-down;
        width: 75px;
        max-height: 35px;
      }
    }
    th{
      border: 1px solid black;
      text-transform: capitalize;
    }
  }
}

table {
  border-collapse: collapse;
  // width: 100%;
  font-size:14px;
  box-sizing: border-box;
  
    td, th {
      border: 1px solid #64706c;
      text-align: left;
      padding: 8px;
    }
}


tr:nth-child(even) {
  background-color: #F5F5F5;
}


.margin-table{
  padding:15px;
}

.no-margin{
  margin-top:0 !important;
}

.operated-by{
  text-transform: capitalize;
}
.api-intro {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  border-top-width:0;
  img {
    max-width: 50%;
  }
  .section-title {
    span {
      font-size: 16px;
    }
  }
}

.api-intro-container{
  padding-bottom: 36px;
  background-color: $main-bg;
}
.resultsbox {
  // margin-top: 30px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  flex: 5;
  clear: both;
  outline: none;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid $brand-primary;
  padding:10px;
  border-radius: 0 5px 5px 5px;
}

span.operatedBy {
  text-transform: capitalize;
}


.lineOne {
  font-weight: 600;
}

.left-margin-75 {
  // margin-left: 85px;
}
.left-margin-10 {
  // margin-left: 0px;
}


.selectbutton {
  border-radius: 5px 5px 0px 0px;
  border: 4px solid $brand-primary;
  padding: 5px;
  max-width: 50%;
  border-bottom-width: 0px;
  cursor: pointer;
  background-color: $brand-primary;
  color: $white;
}

#selectable {
  margin-top: 0;
}

.row{
  display: flex;
  flex-direction: column;
  font-family: ralewayregular;
  // background: red;
  font-size:14px;
  
}
.row:last-child .transit{
  display: none;
}
.row:last-child .main-content .image-container{
  margin-bottom:0;
}
.main-content{
  display: flex;
}
.transit{
  margin-top:5px;
  margin-bottom:5px;
  font-size:12px;
  line-height: 16px;

}
.result-text{
  // background: blue;
  text-align: left;
  // margin-left:10px;
  p{
    margin:0;
    padding:0;
  }
}

.image-container{
  margin-right:10px;
display: flex;
align-items: center;
margin-bottom:16px;
img {
  object-fit: scale-down;
  width: 75px;
  max-height: 35px;
}
}

@font-face {
  font-family: "ralewayregular";
  src: url("/fonts/raleway-regular-webfont.woff2") format("woff2"),
    url("/fonts/raleway-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sarosregular";
  src: url("/fonts/saros-regular-webfont.woff2") format("woff2"),
    url("/fonts/saros-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@import "./components/Header.scss";
@import "./components/FormEntry.scss";
@import "./components/FormOptions.scss";
@import "./components/AdvertisingBox.scss";
@import "./components/Blurb.scss";
@import "./components/Nav.scss";
@import "./components/ResultsBox.scss";
@import "./components/variables.scss";
@import "./components/ResultsTable.scss";
@import "./components/Footer.scss";
@import "./components/Gdpr.scss";
@import "./components/Loader.scss";
@import "./components/Names.scss";
@import "./components/TransitTime.scss";
@import "./components/InvalidInput.scss";
@import "./components/Blog.scss";
@import "./components/Adblock.scss";
@import "./components/Banner.scss";
@import "./components/pages/AboutUs";
@import "./components/pages/CarbonOffset";
@import "./components/ApiIntroduction.scss";
@import "./components/HowTo.scss";
@import "./components/Article.scss";
@import "./components/auth/SignUpWindow";
@import "./components/auth/SignUpPage";
@import "./components/members/Members";
@import "./components/ui/Icons";
@import "./components/stripe/Stripe.scss";
@import "./components/auth/passwordReset";
@import "./components/ui/formElements";

body,
html {
  height: 100%;
}
.content-wrapper {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.body {
  width: 100%;
  // background-color: #25252a;
  margin-left: auto;
  margin-right: auto;
}
#root {
  display: flex;
  flex-direction: column;
}

.container {
  width: 96%;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr auto;
  form {
    width: 100%;
    display: flex;
  }
}

.floatLeft {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.floatRight {
  display: none;
  @media (min-width: $tablet) {
    display: block;
    flex: 1;
  }
}

.App {
  text-align: center;
  background-color: $main-bg;
  height: 100%;
  min-height: calc(100vh - 210px);
}

.shadow {
  -webkit-box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 26px -13px rgba(0, 0, 0, 0.75);
  transition: 0.25s;
}

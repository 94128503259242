.gdpr {
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  padding: 20px;
  background-color: $black;
  box-sizing: border-box;
  font-family: $font-regular;
  color: $grey-light;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index:1000;
  p,
  a {
    font-size: 14px;
  }
  h1 {
    font-size: 16px;
  }
  button {
    height: 30px;
    width: 189px;
    border: 0;
    border-radius: 5px;
    background-color: $brand-primary;
    font-family: $font-condensed;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin:20px;
  }
  @media (min-width: $desktop) {
    flex-direction: row;
    p,
    a {
      font-size: 12px;
    }
    button{
      margin:0;
    }
  }
}
